import { useMemo } from "react"
import { Checkbox, Form } from "formik-antd"
import { Typography } from "antd"
import { FormattedMessage } from "react-intl"

const { Text } = Typography

function AgreeCheckbox({ merchantCode }) {
  const text1 = useMemo(() => {
    switch (merchantCode) {
      case "fortiva":
        return "Payment Solution Providers"
      default:
        return "partners"
    }
  }, [merchantCode])

  const text2 = useMemo(() => {
    if (
      merchantCode === "fortiva" ||
      merchantCode === "valliani-greatmall" ||
      merchantCode === "aff-lto" ||
      merchantCode === "valliani-southland"
    ) {
      return "payment options"
    }
    return "personal loan offers"
  }, [merchantCode])

  return (
    <div style={{ textAlign: "center" }}>
      <Form.Item
        name="agree"
        required
        validate={(value) => {
          if (value !== true) {
            return (
              <FormattedMessage
                id="validation.agree"
                defaultMessage="Please agree to continue"
              />
            )
          }
          return undefined
        }}
      >
        {merchantCode === "trek" ? (
          <Checkbox name="agree">
            <Text
              style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
            >
              By checking this box, I acknowledge I have accessed, read, and
              agree to your{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.citizensbank.com/account-safeguards/privacy.aspx"
              >
                Privacy Policy
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.citizensbank.com/disclosures/recurring-payment.aspx"
              >
                Recurring Payment
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.citizensbank.com/disclosures/APR-eConsent.aspx"
              >
                Consent to Electronic Documents
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.citizensbank.com/disclosures/APR-verification-of-identity.aspx"
              >
                Verification of My Identity
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.paypossible.com/account-tos"
              >
                PayPossible Terms of Service
              </a>
              , I authorize Citizens Pay to obtain my credit report to determine
              financing options, and share my information with PayPossible and
              retailers about my prequalification request, application, and line
              of credit. Rate estimates/terms subject to change after full
              application review. A subsequent application will result in an
              inquiry on your credit report. Applications subject to individual
              approval. <br />
              Checking eligibility will not impact your credit score. Upon
              submitting a full application, Citizens Pay will complete a full
              credit review that will result in a hard inquiry on your credit
              file that may impact your credit score. Applications subject to
              individual approval.
            </Text>
          </Checkbox>
        ) : (
          <Checkbox name="agree">
            <Text
              style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
            >
              By submitting this form, I consent to PayPossible's{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.paypossible.com/electronic-consent"
              >
                E-Consent Agreement
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.paypossible.com/credit-authorization-agreement"
              >
                Credit Authorization Agreement
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.paypossible.com/privacy-policy"
              >
                Privacy Policy
              </a>
              ,{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.paypossible.com/account-tos"
              >
                Terms of Service
              </a>
              , and am providing written consent under the FCRA for PayPossible,
              Inc. and its partners to obtain consumer report information from
              my credit profile. I agree to be contacted by PayPossible and its{" "}
              {text1}
              at the telephone number(s) I have provided above to explore{" "}
              {text2}, including contact through automatic dialing systems,
              artificial or pre-recorded voice messaging, or text message. I
              understand my consent is not required as a condition to purchase
              any goods or services from anyone.
            </Text>
          </Checkbox>
        )}
      </Form.Item>
    </div>
  )
}
export default AgreeCheckbox
