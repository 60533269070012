import React, { useState, useEffect, useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import {
  Alert,
  Divider,
  Table,
  Typography,
  Col,
  Row,
  Statistic,
  Button,
  Card,
  Badge,
} from "antd"
import Loader from "../../components/core/Loader"
import { MerchantInfo, OrderInfo } from "../descriptions"
import { formatStatus, getStatusColor } from "../../utils/formatters"
import FrameModal from "../modals/FrameModal"
import OrderApproveModal from "../modals/OrderApproveModal"
import DocuSignModal from "../modals/DocuSignModal"
import sleep from "../../utils/sleep"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import OrderProcessingModal from "../modals/OrderProcessingModal"

const { Title } = Typography

function OrderDetailCard(props) {
  const { themeColor, merchantLogo } = useTheme()
  const { merchantId } = useApply()

  const [showApprove, setShowApprove] = useState(false)
  const [showContract, setShowContract] = useState(false)
  const [isLoadingDocusign, setIsLoadingDocusign] = useState(true)
  const [isApprove, setIsApprove] = useState(true)
  const [isLoadingOrder, setIsLoadingOrder] = useState(false)
  const [isTryApprove, setIsTryApprove] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    if (props?.isApprove) {
      setShowApprove(props.isApprove)
    }
    if (props.order.status === "approved") {
      window.parent &&
        window.parent.postMessage("paypossible_order_success", "*")
    }
  }, [props])

  const handleApprove = () => {
    if (merchantId === "trek") {
      setIsProcessing(true)
    } else {
      props.onApprove()
      setIsApprove(false)
      setShowApprove(false)
    }
  }

  const handleProcessing = () => {
    props.onApprove()
    setIsProcessing(false)
    setIsApprove(false)
    setShowApprove(false)
  }

  const handleApproveBtn = () => {
    if (props.order.loan.originator.key !== "ownlease") {
      setShowApprove(true)
      return
    }
    setIsTryApprove(false)
    handleApprove()
    setIsLoadingOrder(true)
  }

  const title = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return `Here's your confirmation`
      default:
        return `Your ${props.order.merchant.name} Order`
    }
  }, [merchantId, props.order])

  useEffect(() => {
    if (
      props.order?.approval_url !== null &&
      (isLoadingOrder || isTryApprove)
    ) {
      setIsLoadingOrder(false)
      setIsTryApprove(false)
      setIsApprove(false)
    }
    /* eslint-disable */
  }, [props.order])

  const checkApprovalUrl = useCallback(() => {
    if (
      props.order.status === "approving" &&
      props.order.approval_url === null
    ) {
      setIsLoadingOrder(false)
      setIsTryApprove(true)
      setIsApprove(true)
    }

    if (props.order.status === "sent" && props.order.approval_url === null) {
      setIsApprove(true)
      setIsLoadingOrder(false)
      setIsTryApprove(false)
    }
  }, [props.order])

  useEffect(() => {
    ;(async () => {
      if (isTryApprove) {
        await sleep(30000)
        setIsTryApprove(false)
      }
    })()
  }, [isTryApprove])

  useEffect(() => {
    ;(async () => {
      if (isLoadingOrder) {
        await sleep(30000)
        setIsLoadingOrder(false)
      }
      if (!isLoadingOrder) {
        checkApprovalUrl()
      }
    })()
  }, [isLoadingOrder])

  if (!props.order) {
    return null
  }

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "description",
      key: "description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <p>{text}</p>,
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <h5 className="hp-text-align-right">{text}</h5>,
      align: "right",
    },
  ]

  return (
    <Badge.Ribbon
      text={formatStatus(props.order.status)}
      color={getStatusColor("order", props.order.status, themeColor)}
    >
      <Card>
        <Row justify="center">
          <Title level={4}>{title}</Title>
        </Row>
        <Row justify="center">
          <Col>
            <Statistic title="Order Total" value={`$${props.order.total}`} />
          </Col>
        </Row>
        <Divider />
        {isTryApprove && (
          <Alert
            message={`Please wait, generating contract...`}
            showIcon
            type="success"
            style={{ marginBottom: 20 }}
          />
        )}
        {(props.order.status === "approved" ||
          props.order.status === "paid") && (
          <Alert
            message={`Congratulations, your purchase is complete!`}
            showIcon
            type="success"
            closable
            style={{ marginBottom: 20 }}
          />
        )}
        {props.order.actions.approve &&
          (props.order.status !== "approving" ||
            props.order.approval_url === null) &&
          isApprove && (
            <>
              <Alert
                message={`You are almost done! To approve your order, click APPROVE.`}
                type="success"
                closable
                style={{ marginBottom: 10 }}
              />
              <Row justify="center">
                <Button type="primary" onClick={handleApproveBtn}>
                  APPROVE
                </Button>
              </Row>
            </>
          )}

        {props.order.approval_url !== null && (
          <Row justify="center">
            {props.order.loan.originator.key === "ownlease" ? (
              <Button type="primary" onClick={() => setShowContract(true)}>
                Final Step
              </Button>
            ) : (
              <Button type="primary" onClick={() => setShowContract(true)}>
                Sign Documents
              </Button>
            )}
          </Row>
        )}
        <Row justify="space-between" gutter={[16, 16]}>
          <Col md={8} xs={24}>
            <MerchantInfo merchant={props.order.merchant} title="Merchant" />
          </Col>
          <Col md={8} xs={24}>
            <OrderInfo order={props.order} title="Order Details" />
            {props.order.loan.originator.key !== "ownlease" && (
              <Link
                to={{
                  pathname: `/loans/${props.order.loan.id}`,
                  state: { fromOrder: true },
                }}
              >
                View Account
              </Link>
            )}
          </Col>
        </Row>
        <Divider />
        <Table
          columns={columns}
          dataSource={props.order.items}
          bordered={false}
          rowKey="id"
          size="small"
          pagination={false}
        />
        <br />
        <Row justify="end">
          <Col md={6} sm={12} xs={24} className="hp-pb-16 hp-print-checkout">
            {Number(props.order.subtotal) !== Number(props.order.total) && (
              <Row align="middle" justify="space-between">
                <p className="basic-par">Subtotal</p>
                <p className="basic-par">$ {props.order.subtotal}</p>
              </Row>
            )}
            {Number(props.order.discount) !== 0 && (
              <Row align="middle" justify="space-between">
                <p className="basic-par">Discount</p>
                <p className="basic-par">-$ {props.order.discount}</p>
              </Row>
            )}
            {Number(props.order.tax) !== 0 && (
              <Row align="middle" justify="space-between">
                <p className="basic-par">Tax</p>
                <p className="basic-par">$ {props.order.tax}</p>
              </Row>
            )}
            {Number(props.order.shipping) !== 0 && (
              <Row align="middle" justify="space-between">
                <p className="basic-par">Shipping</p>
                <p className="basic-par">$ {props.order.shipping}</p>
              </Row>
            )}
            <Divider />
            <Row align="middle" justify="space-between">
              <p className="basic-par">Total</p>
              <p className="basic-par">$ {props.order.total}</p>
            </Row>
          </Col>
        </Row>
      </Card>
      <FrameModal
        showModal={showContract && props.order.status === "approving"}
        title="Approve Terms"
        setShow={setShowContract}
        url={props.order.approval_url}
        setLoading={setIsLoadingDocusign}
      />
      <OrderApproveModal
        order={props.order}
        onCancel={() => setShowApprove(false)}
        visible={showApprove}
        onApprove={handleApprove}
      />
      <OrderProcessingModal
        visible={isProcessing}
        logo={merchantLogo}
        onClose={handleProcessing}
      />
      <DocuSignModal
        visible={
          showContract &&
          props.order.status === "approving" &&
          isLoadingDocusign
        }
        onCancel={() => setIsLoadingDocusign(false)}
      />
      <Loader
        visible={isLoadingOrder}
        title={"Finalizing Order"}
        body={"Please wait while we process your order"}
      />
      <Loader
        visible={isTryApprove}
        title={"Finalizing Order"}
        body={"Please wait, generating contract..."}
      />
      {/* {false && (
        <Modal
          visible={showContract && props.order.status === "approving"}
          title="Approve Terms"
          width="100vw"
          centered
          footer={null}
          bodyStyle={{ height: isMobile ? "70vh" : "85vh", padding: 0 }}
          onCancel={() => setShowContract(false)}
          onOk={() => setShowContract(false)}
        >
          <iframe
            title="Terms"
            height="100%"
            frameBorder="0"
            width="100%"
            src={props.order.approval_url}
          />
          <a href={props.order.approval_url} rel="noreferrer" target="_blank">
            Open In New Window
          </a>
        </Modal>
      )} */}
    </Badge.Ribbon>
  )
}

export default OrderDetailCard
