import { Form, Input } from "formik-antd"
import * as React from "react"
import moment from "moment"

function DateTextInput(props) {
  const validateDate = (value) => {
    if (value.length < 10)
      return props?.errorMessage || "Please enter a valid date."
    const date = moment(value)
    if (!date.isValid())
      return props?.errorMessage || "Please enter a valid date."

    const now = moment()
    const difference = now.diff(date, "years")

    if (difference > 100 || difference < 0)
      return props?.errorMessage || "Please enter a valid date."

    return undefined
  }

  const validateBirthday = (value) => {
    if (value.length < 10)
      return props?.errorMessage || "Please enter a valid date."
    const birthday = moment(value)
    if (!birthday.isValid())
      return props?.errorMessage || "Please enter a valid date."

    const now = moment()
    const difference = now.diff(birthday, "years")

    if (difference > 100)
      return props?.errorMessage || "Please enter a valid date."
    if (difference < 18)
      return "You must be at least 18 years old to be eligibile."

    return undefined
  }

  const validateFuture = (value) => {
    const basicValidation = validateDate(value)
    if (typeof basicValidation === "string") return basicValidation
    const date = moment(value)
    if (date.isAfter()) {
      return undefined
    }

    return "Please enter a date in the future"
  }

  function validatePast(value) {
    const basicValidation = validateDate(value)
    if (typeof basicValidation === "string") return basicValidation
    const date = moment(value)
    if (date.isBefore()) {
      return undefined
    }
    return "Please enter a date in the past"
  }

  let validator = validateDate
  if (props.isBirthday) validator = validateBirthday
  if (props.validator && props.validator === "future")
    validator = validateFuture
  if (props.validator && props.validator === "past") validator = validatePast

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      tooltip={props.tooltip}
      validate={validator}
    >
      <Input
        name={props.name}
        placeholder={props.placeholder}
        size="large"
        inputMode="numeric"
        onChange={(e) => {
          if (props.placeholder === "YYYY-MM-DD") {
            let display = ""
            let value = e.target.value.replace(/\D/g, "")

            if (e.nativeEvent.inputType === "deleteContentBackward") {
              console.log("target is", e.target.value, "value is", value)
              console.log("last car", e.target.value[e.target.value.length - 1])
              if (e.target.value.length === 4 || e.target.value.length === 7) {
                value = value.slice(0, value.length - 1)
              }
            }

            if (value.length < 4) {
              display = value
            } else if (value.length === 4) {
              display = `${value}-`
            } else if (value.length < 6) {
              display = `${value.slice(0, 4)}-${value.slice(4)}`
            } else if (value.length === 6) {
              display = `${value.slice(0, 4)}-${value.slice(4, 6)}-`
            } else {
              display = `${value.slice(0, 4)}-${value.slice(
                4,
                6
              )}-${value.slice(6, 8)}`
            }
            props.formik.setFieldValue(props.name, display)
          }
          if (props.placeholder === "MM/DD/YYYY") {
            let display = ""
            let value = e.target.value.replace(/\D/g, "")

            if (e.nativeEvent.inputType === "deleteContentBackward") {
              console.log("target is", e.target.value, "value is", value)
              console.log("last car", e.target.value[e.target.value.length - 1])
              if (e.target.value.length === 2 || e.target.value.length === 5) {
                value = value.slice(0, value.length - 1)
              }
            }

            if (value.length < 2) {
              display = value
            } else if (value.length === 2) {
              display = `${value}/`
            } else if (value.length < 4) {
              display = `${value.slice(0, 2)}/${value.slice(2)}`
            } else if (value.length === 4) {
              display = `${value.slice(0, 2)}/${value.slice(2, 4)}/`
            } else {
              display = `${value.slice(0, 2)}/${value.slice(
                2,
                4
              )}/${value.slice(4, 8)}`
            }
            props.formik.setFieldValue(props.name, display)
          }
        }}
      />
    </Form.Item>
  )
}
export default DateTextInput
