import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { Form, DatePicker } from "formik-antd"
import { Typography, Row, Col, Button, Modal, Alert } from "antd"
import { MoneyInput, PayrollFrequencySelect } from "../inputs"
import ComplianceAgree from "../compliance/ComplianceAgree"
import { validateFuture, validatePast } from "../../utils/validation"
import { useTheme } from "../../hooks/useTheme"

const { Title, Text } = Typography

function OwnleaseForm({
  values,
  onSubmit,
  offer,
  securePaymentDebitCardUrl,
  debitCardToken,
  isSubmitted,
  setIsSubmitted,
}) {
  const { isMobile } = useTheme()

  // const [isShowDebitPopup, setIsShowDebitPopup] = useState(true)
  const [isShowDebitFormPopup, setIsShowDebitFormPopup] = useState(true)

  // const handleClosePopup = () => {
  //   setIsShowDebitPopup(false)
  // }

  const handleAppy = (formik) => {
    setIsSubmitted(true)
    formik.handleSubmit()
  }

  useEffect(() => {
    if (debitCardToken !== "") {
      setIsShowDebitFormPopup(false)
    }
  }, [debitCardToken])

  function renderIncome(formik) {
    if (!values.hasOwnProperty("income")) return null
    return (
      <>
        <Title style={{ textAlign: "left" }} level={5}>
          Income Information
        </Title>
        <Text>Additional income information is needed by this provider</Text>
        <br />
        <br />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <MoneyInput
              name="income.net_monthly_income"
              min={1000}
              hasFeedback
              label="Net Monthly Income"
              tooltip="Your monthly income after taxes."
              formik={formik}
              placeholder="$ Net Monthly Income"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <PayrollFrequencySelect />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.last_pay_date"
              label="Last Pay Date"
              hasFeedback
              validate={validatePast}
            >
              <DatePicker
                name="income.last_pay_date"
                size="large"
                format="MM/DD/YYYY"
                style={{ width: "100%" }}
                label="Last Pay Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.next_pay_date"
              label="Next Pay Date"
              hasFeedback
              validate={validateFuture}
            >
              <DatePicker
                name="income.next_pay_date"
                size="large"
                format="MM/DD/YYYY"
                style={{ width: "100%" }}
                label="Next Pay Date"
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Formik initialValues={values} onSubmit={onSubmit}>
        {(formik) => (
          <Form layout="vertical" style={{ width: "100%" }}>
            {false && (
              <>
                {renderIncome(formik)}
                <Row justify="center">
                  <div style={{ marginTop: 20 }}>
                    <Button
                      type="primary"
                      // onClick={() => setStep("securepayment")}
                    >
                      Continue
                    </Button>
                  </div>
                </Row>
              </>
            )}
            {/* {step === "securepayment" &&
              offer.actions?.securepayment_debit_card &&
              securePaymentDebitCardUrl && (
                <iframe
                  title="secure_payment_debit_card"
                  src={securePaymentDebitCardUrl}
                  style={{ border: "1px solid black", marginTop: 30 }}
                  height={700}
                  width="100%"
                  frameBorder="0"
                />
              )} */}

            <div style={{ textAlign: "center" }}>
              <Title level={3}>Review Disclosure and Continue</Title>
              <Alert
                message={"MUST CHECK DISCLOSURE BOXES BELOW TO CONTINUE"}
                showIcon
                type="warning"
                closable
                style={{ marginBottom: 30, marginTop: 20 }}
              />
              {offer.documents?.length && <ComplianceAgree offer={offer} />}
              <div style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  onClick={() => handleAppy(formik)}
                  disabled={isSubmitted}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        visible={isShowDebitFormPopup}
        title={
          <>
            <div style={{ fontSize: 13 }}>
              The debit card will be used to make payments on this account. This
              can be changed later
            </div>
            <div style={{ fontSize: 15, fontWeight: 600 }}>
              Important: Must not be a pre-paid debit card
            </div>
          </>
        }
        width={isMobile ? "365px" : "701px"}
        zIndex={1}
        centered
        footer={null}
        closable={false}
        bodyStyle={{
          height: isMobile ? "80vh" : "70vh",
          padding: 0,
          margin: 0,
        }}
      >
        <iframe
          title={"Debit Card Information"}
          height="100%"
          frameBorder="0"
          width="100%"
          src={securePaymentDebitCardUrl}
        />
      </Modal>
    </>
  )
}

export default OwnleaseForm
