import React, { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"
import { Alert } from "antd"
import OrderDetailCard from "../components/cards/OrderDetailCard"
import { OffersView } from "../components/order"
import { Spinner } from "../components/core/Spinner"
import {
  fetchOrder,
  doApproveOrder,
  doApproveOrderForCitizens,
} from "../services/order.service"
import sleep from "../utils/sleep"
import { useApply } from "../hooks/useApply"

export default function OrderPage() {
  const { order_id } = useParams()
  const { fetchMerchant, setOrder, order, lead, getLead } = useApply()
  // const [order, setOrder] = useState(null)
  const [offer, setOffer] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [showApprove, setShowApprove] = useState(false)

  const syncOrder = useCallback(async () => {
    const orderResponse = await fetchOrder(order_id)
    if (orderResponse.success) {
      setOrder(orderResponse.data)
      setError(null)
      setLoading(false)
    } else {
      setOrder(null)
      setLoading(false)
      setError(orderResponse.error)
    }
    // eslint-disable-next-line
  }, [order_id])

  /**
   * Load and sync the order
   *
   * Loads the initial order and syncs if status is applying
   */
  useEffect(() => {
    let mounted = true

    ;(async () => {
      if (!order) {
        syncOrder()
      } else if (order && order.status === "approving") {
        await sleep(3000)
        if (mounted) {
          syncOrder()
        }
      }
    })()

    return () => (mounted = false)
  }, [order, syncOrder])

  useEffect(() => {
    if (order) {
      fetchMerchant(order.merchant.code)
      getLead(order.lead.id)
    }
    //eslint-disable-next-line
  }, [order])

  useEffect(() => {
    if (
      lead?.is_shopify &&
      order?.status === "approved" &&
      lead?.actions?.redirect
    ) {
      window.location.replace(lead?.actions?.redirect)
    }
  }, [lead, order])

  const handleApprove = async () => {
    let response = {}

    if (order.loan?.originator?.key === "citizens") {
      let body = {}
      if (Object.keys(offer).length > 0) {
        body = {
          offer: {
            url: offer.url,
          },
        }
      }
      response = await doApproveOrderForCitizens(order.id, body)
    } else {
      response = await doApproveOrder(order.id)
    }
    setOrder(response.data)
  }

  if (error) {
    alert(error)
  }

  if (loading || !order) {
    return <Spinner />
  }

  // return <OrderView order={order} approveOrder={handleApprove} />

  return (
    <>
      {order.approval_url && (
        <Alert
          message="Please sign the documents to complete your order"
          type="error"
          showIcon
          style={{ marginTop: 0, marginBottom: 5 }}
        />
      )}
      {order.errors &&
        order.errors.map((e) => (
          <Alert
            message={e}
            key={e}
            type="error"
            showIcon
            style={{ marginTop: 0, marginBottom: 5 }}
          />
        ))}
      {order.offers?.length > 0 && order.status === "sent" ? (
        !showApprove ? (
          <OffersView
            order={order}
            onApprove={handleApprove}
            offer={offer}
            setOffer={setOffer}
            showApprove={showApprove}
            setShowApprove={setShowApprove}
          />
        ) : (
          <OrderDetailCard
            order={order}
            onApprove={handleApprove}
            isApprove={showApprove}
          />
        )
      ) : (
        <OrderDetailCard order={order} onApprove={handleApprove} />
      )}
    </>
  )
}
