import React, { useMemo } from "react"
import { Result } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import { useTheme } from "../../hooks/useTheme"

function LoanDeclineView({ loan }) {
  const { colors } = useTheme()

  const subTitle = useMemo(() => {
    switch (loan.merchant.code) {
      case "trek":
        return "Citizens Pay is unable to approve your application."
      case "flexshopper":
        return "A letter will be sent to the address on your application with additional details"
      case "flexshopper-external":
        return "A letter will be sent to the address on your application with additional details"
      default:
        return "Unfortunately, your account has been declined"
    }
  }, [loan])

  const title = useMemo(() => {
    switch (loan.merchant.code) {
      case "flexshopper":
        return "Your application was unable to be approved"
      default:
        return "Declined"
    }
  }, [loan])

  const description = useMemo(() => {
    if (loan.merchant.code === "trek") {
      return "You'll receive additional information within 30 days regarding this decision."
    }
    return `If you did not receive offers you will receive an Adverse Action
    Notice via email or in the mail, from the Payment Solution Providers
    that did not offer you an approval. An Adverse Action Notice will detail
    your true credit score and the reason you receive a status of 'No
    Offers.' If you did not receive one for some reason please check your
    spam folder, or you can contact the Payment Solution Providers directly.`
  }, [loan])

  return (
    <Result
      status="error"
      title={title}
      subTitle={subTitle}
      icon={<CloseCircleFilled style={{ fontSize: 64, color: colors.red }} />}
      style={{ padding: 10 }}
    >
      <h5 style={{ textAlign: "center" }}>{description}</h5>
    </Result>
  )
}
export default LoanDeclineView
