import React, { useState, createContext, useContext } from "react"
import { notification, ConfigProvider } from "antd"
import { getMerchant } from "./requests"
import { fetchLead } from "../services/lead.service"
import { useTheme } from "./useTheme"
import api from "../services/api"
import BRANDS from "../utils/merchantBranding"

const ApplyContext = createContext(null)

export function ApplyProvider({ children }) {
  const merchantIdValue = sessionStorage.getItem("mid")
  const {
    setMerchantColor,
    setMerchantLogo,
    setThemeColor,
    setMenuLogo,
    setPoweredByLogo,
  } = useTheme()
  const [merchantId, setMerchantId] = useState(merchantIdValue)
  const [lead, setLead] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [merchant, setMerchant] = useState(null)
  const [appsMenu, setAppsMenu] = useState(true)
  const [loan, setLoan] = useState(null)
  const [productURL, setProductURL] = useState(null)
  const [order, setOrder] = useState(null)

  const updateMerchantId = async (merchantId) => {
    sessionStorage.setItem("mid", merchantId)
    setMerchantId(merchantId)
    return fetchMerchant(merchantId)
  }

  const getLead = async (leadId) => {
    try {
      // const result = await api.get(API_URL + `/v1/leads/${leadId}/`);
      const result = await fetchLead(leadId)
      console.log("result is", result)
      if (result.data) {
        setLead(result.data)
        setMerchant(result.data.merchant)
        updateMerchantId(result.data.merchant.code)
      }
    } catch (err) {
      console.log("err", err)
    }
  }

  const fetchMerchant = async (merchantId) => {
    try {
      setLoading(true)
      const result = await api(getMerchant(merchantId))
      if (result.data) {
        setError(null)
        sessionStorage.setItem("mid", merchantId)
        setMerchant(result.data)
        if (merchantId in BRANDS) {
          setMerchantColor(BRANDS[merchantId].color)
          setMerchantLogo(BRANDS[merchantId].img)

          if (BRANDS[merchantId]?.dark) {
            if (BRANDS[merchantId]?.dark === "undefined") {
              setPoweredByLogo(undefined)
            } else {
              setPoweredByLogo(BRANDS[merchantId].dark)
            }
          }
          if (BRANDS[merchantId]?.themeColor) {
            setThemeColor(BRANDS[merchantId].themeColor)
            setMenuLogo(BRANDS[merchantId].img)
            ConfigProvider.config({
              theme: {
                primaryColor: BRANDS[merchantId].themeColor,
                infoColor: BRANDS[merchantId].themeColor,
                successColor: BRANDS[merchantId].color,
              },
            })
          }
        }
        setLoading(false)
        return { success: true, merchant: result.data }
      }
    } catch (err) {
      setMerchant(null)
      setError(err)
      setLoading(false)
      if (err.response?.status === 404) {
        notification.error({
          message: "Error",
          description: "Not found, please enter a valid ID",
          duration: 5,
        })
        return { success: false, error: err }
      }
    }
  }

  return (
    <ApplyContext.Provider
      value={{
        lead,
        setLead,
        getLead,
        merchant,
        setMerchant,
        updateMerchantId,
        error,
        loading,
        fetchMerchant,
        setMerchantId,
        merchantId,
        appsMenu,
        setAppsMenu,
        loan,
        setLoan,
        productURL,
        setProductURL,
        order,
        setOrder,
      }}
    >
      {children}
    </ApplyContext.Provider>
  )
}

export const useApply = () => useContext(ApplyContext)
