import React, { useEffect, useState, useMemo } from "react"
import { Row, Col, Typography, Button, Input } from "antd"
import { useHistory } from "react-router-dom"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
import PoweredBy from "../core/PoweredBy"
import { ProductCard, CategoryCard } from "../../components/cards"
import { getProducts, getCategories } from "../../services/merchant.service"

const { Text } = Typography

function ProductSelectLander(props) {
  const { merchant, login } = props
  console.log(merchant)
  const { merchantId, setProductURL } = useApply()
  const { push } = useHistory()
  const { poweredByLogo } = useTheme()
  const [searchKey, setSearchKey] = useState("")
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const [preUrl, setPreUrl] = useState(null)
  const [catNextUrl, setCatNextUrl] = useState(null)
  const [catPreUrl, setCatPreUrl] = useState(null)
  const [selectCatId, setSelectCatId] = useState(null)

  const handleSearch = async () => {
    if (selectCatId) {
      let uri = `${merchant.business.url}products?is_active=1&category_id=${selectCatId}&search=${searchKey}`
      const result = await getProducts(uri)
      setProdcutResults(result)
    }
  }

  const handlePagination = async (url) => {
    if (selectCatId) {
      const result = await getProducts(url)
      setProdcutResults(result)
    } else {
      const result = await getCategories(url)
      setCategoryResults(result)
    }
  }

  const setProdcutResults = (result) => {
    if (result.success) {
      setProducts(result.data?.results)
      setNextUrl(result.data?.next)
      setPreUrl(result.data?.previous)
    } else {
      setProducts([])
      setNextUrl(null)
      setPreUrl(null)
    }
  }

  const setCategoryResults = (result) => {
    if (result.success) {
      setCategories(result.data?.results)
      setCatNextUrl(result.data?.next)
      setCatPreUrl(result.data?.previous)
    } else {
      setCategories([])
      setCatNextUrl(null)
      setCatPreUrl(null)
    }
  }

  const selectProduct = (url) => {
    setProductURL(url)
    push("/apply")
  }

  const displayLogo = useMemo(() => {
    switch (merchant?.code) {
      case "ace-hardware":
        return (
          <>
            <Row align="middle" justify="center">
              <img src={props.img} width="30%" alt="merchant-logo" />
            </Row>
            <Row align="middle" justify="center">
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: "28px",
                }}
              >
                Ace Hardware - {merchant?.address?.city}
              </Text>
            </Row>
            <Row align="middle" justify="center">
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {merchant?.address?.street1}, {merchant?.address?.city},{" "}
                {merchant?.address?.state}, {merchant?.address?.zip}
              </Text>
            </Row>
            <Row
              align="middle"
              style={{
                borderTop: "2px",
                border: "2px solid black",
                borderLeft: 0,
                borderRight: 0,
                marginTop: 15,
                padding: 10,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: "28px",
                  width: "100%",
                  textDecoration: "underline",
                }}
              >
                To Check your Eligibility
              </Text>
              <Row align="middle" style={{ marginTop: 3 }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                  }}
                >
                  Step1:{" "}
                  <Text
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    Select the product you would like to purchase
                  </Text>
                </Text>
              </Row>
              <Row align="middle" style={{ marginTop: 3 }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                  }}
                >
                  Step2:{" "}
                  <Text
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    Provide some basic personal details
                  </Text>
                </Text>
              </Row>
              <Row align="middle" style={{ marginTop: 3 }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                  }}
                >
                  Step3:{" "}
                  <Text
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    Select and review your offer to complete your purchase
                  </Text>
                </Text>
              </Row>
            </Row>
          </>
        )
      default:
        return (
          <Row align="middle" justify="center">
            <img src={props.img} width="80%" alt="merchant-logo" />
          </Row>
        )
    }
  }, [merchant, props.img])

  useEffect(() => {
    if (merchant?.business?.url) {
      ;(async function () {
        const result = await getCategories(merchant.business.url)
        setCategoryResults(result)
      })()
    }
  }, [merchant])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line
  }, [selectCatId])

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        height: "100%",
        position: "relative",
      }}
    >
      <Row align="middle" justify="space-around">
        <Col
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "10rem",
            maxWidth: 380,
            width: "100%",
          }}
        >
          {displayLogo}
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "28px",
              }}
            >
              Please enter the Product Name or SKU of the product you'll be
              purchasing
            </Text>
          </Row>
          <Row
            justify="center"
            style={{ marginTop: 20, height: 45, width: "100%" }}
          >
            <Input
              placeholder="Product Name/SKU"
              style={{ textAlign: "center" }}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </Row>
          <Row align="middle" justify="center">
            <Button
              shape="default"
              style={{
                color: "white",
                backgroundColor: "black",
                marginTop: 20,
                padding: "0px 30px",
              }}
              onClick={() => handleSearch(merchant.business.url, searchKey)}
            >
              Search
            </Button>
          </Row>

          <Row
            align="middle"
            justify="center"
            style={{ marginTop: 20, width: "100%" }}
          >
            {!selectCatId
              ? categories?.map((item, index) => (
                  <CategoryCard
                    item={item}
                    setSelectCatId={setSelectCatId}
                    key={index}
                  />
                ))
              : products.map((item, index) => (
                  <ProductCard
                    item={item}
                    selectProduct={selectProduct}
                    key={index}
                  />
                ))}
            {(categories.length > 0 || products.length > 0) && (
              <Row
                align="middle"
                justify="space-around"
                style={{ marginTop: 30 }}
              >
                <Button
                  type="primary"
                  style={{
                    marginRight: 7,
                  }}
                  disabled={(selectCatId ? preUrl : catPreUrl) === null}
                  onClick={() =>
                    handlePagination(selectCatId ? preUrl : catPreUrl)
                  }
                >
                  Previous
                </Button>
                <Button
                  type="primary"
                  style={{
                    marginLeft: 7,
                  }}
                  disabled={(selectCatId ? nextUrl : catNextUrl) === null}
                  onClick={() =>
                    handlePagination(selectCatId ? nextUrl : catNextUrl)
                  }
                >
                  Next
                </Button>
              </Row>
            )}
          </Row>
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 15,
                lineHeight: "28px",
              }}
            >
              {merchantId === "trek" && (
                <>
                  Checking eligibility{" "}
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    will not impact
                  </Text>{" "}
                  your credit score
                </>
              )}
            </Text>
          </Row>
          {poweredByLogo && <PoweredBy />}
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 30 }}>
        {login && login}
      </Row>
    </div>
  )
}

export default ProductSelectLander
