import { useEffect, useState } from "react"
import { Form, Input } from "formik-antd"
import React from "react"

function SsnInput(props) {
  const [isMask, setIsMask] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const ssnValue = props.formik.values?.personal?.ssn

  useEffect(() => {
    if (validateSSN(ssnValue, props?.errorMessage)) {
      setIsShow(false)
    } else {
      if (isMask) {
        setIsShow(true)
      } else {
        setIsShow(false)
      }
    }
    // eslint-disable-next-line
  }, [isMask, ssnValue])

  function validateSSN(value) {
    return value && value.length <= 12
      ? undefined
      : props?.errorMessage || "A valid SSN is required"
  }

  return (
    <Form.Item
      name={props.name}
      hasFeedback={props.hasFeedback}
      showValidateSuccess={props.showValidateSuccess}
      tooltip={props.tooltip}
      label={props.label}
      validate={validateSSN}
    >
      {isShow ? (
        <Input
          data-openreplay-obscured
          data-private="lipsum"
          name={props.name}
          placeholder={props.placeholder}
          style={{ width: "100%" }}
          size="large"
          onFocus={() => setIsMask(false)}
          onBlur={() => setIsMask(true)}
          value="***-**-****"
        />
      ) : (
        <Input
          data-openreplay-obscured
          data-private="lipsum"
          name={props.name}
          placeholder={props.placeholder}
          style={{ width: "100%" }}
          inputMode="numeric"
          size="large"
          onFocus={() => setIsMask(false)}
          onBlur={() => setIsMask(true)}
          onChange={(e) => {
            let display = ""
            const value = e.target.value.replace(/\D/g, "")
            if (value.length < 4) {
              display = value
            } else if (value.length < 6) {
              display = `${value.slice(0, 3)}-${value.slice(3)}`
            } else {
              display = `${value.slice(0, 3)}-${value.slice(
                3,
                5
              )}-${value.slice(5, 9)}`
            }
            props.formik.setFieldValue(props.name, display)
          }}
        />
      )}
    </Form.Item>
  )
}
export default SsnInput
