import { Form, Input } from "formik-antd"
import { validatePhone } from "../../hooks/requests"

function PhoneInput(props) {
  const ValidatePhoneInput = async (phone) => {
    const request = validatePhone(phone)

    fetch(request[0], request[1])
      .then(async (response) => {
        if (response.ok) {
          return undefined
        } else {
          const resp = await response.json()
          return `${resp["number"][0]}`
        }
      })
      .catch((err) => {
        return err.message
      })
    if (phone === undefined) return undefined
    if (phone.length < 14) {
      return props.errorMessage || "Please enter a valid phone number"
    }
  }
  return (
    <Form.Item
      name={props.name}
      hasFeedback={props.hasFeedback}
      label={props.label}
      showValidateSuccess={props.showValidateSuccess}
      required={props.required}
      validate={ValidatePhoneInput}
      tooltip={props?.tooltip || ""}
    >
      <Input
        name={props.name}
        size="large"
        inputMode="numeric"
        placeholder={props.placeholder}
        autoComplete="tel"
        onChange={(e) => {
          let display = ""
          const value = e.target.value.replace(/[^\d]/g, "")
          if (value.length < 4) {
            display = value
          } else if (value.length < 7) {
            display = `(${value.slice(0, 3)}) ${value.slice(3)}`
          } else {
            display = `(${value.slice(0, 3)}) ${value.slice(
              3,
              6
            )}-${value.slice(6, 10)}`
          }
          props.formik.setFieldValue(props.name, display)
        }}
        disabled={props.disabled}
      />
    </Form.Item>
  )
}
export default PhoneInput
