import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Button, Row, Alert } from "antd"
import _ from "underscore"
import { useOffer } from "../hooks/hooks"
import { Spinner } from "../components/core/Spinner"
import GenesisOffer from "../components/lenders/GenesisOffer"
import { useApply } from "../hooks/useApply"
// import AmericanFirstOffer from "../components/lenders/AmericanFirstOffer";
import FortivaOffer from "../components/lenders/FortivaOffer"
import CitizensOffer from "../components/lenders/CitizensOffer"
import OwnLeaseOffer from "../components/lenders/OwnLeaseOffer"
import UownOffer from "../components/lenders/UownOffer"
import FlexshopperOffer from "../components/lenders/FlexshopperOffer"
import { useAuth } from "../hooks/useAuth"
import OfferSummary from "../components/offer/OfferSummary"
// import LenderSummaryModal from "../components/modals/LenderSummaryModal"
import AffFinwiseSummaryModal from "../components/offer/AffFinwiseSummaryModal"
import SingleOfferCard from "../components/cards/SingleOfferCard"
import copy from "../utils/appCopy"
import AffFinwiseOffer from "../components/offer/AffFinwiseOffer"
// import { reapplyLead, pingAndWait } from '../services/lead.service';

function OfferPage() {
  const [showModal, setShowModal] = useState(true)
  // const [reapplying, setReapplying] = useState(false);
  const { user } = useAuth()
  const history = useHistory()
  const { merchant, getLead, lead, merchantId } = useApply()
  const { offer_id } = useParams()
  const { error, loading, data } = useOffer(offer_id)
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    if (!error && (!merchant || !lead)) {
      if (data) {
        ;(async () => {
          await getLead(data.lead?.id)
        })()
      }
    }
  }, [data, error, getLead, lead, merchant])

  useEffect(() => {
    if (data?.status) {
      const { id, status } = data
      window.parent &&
        window.parent.postMessage({ type: "offer", id, status }, "*")
    }
    //eslint-disable-next-line
  }, [data?.status])

  /*
    async function handleReapply() {
        setReapplying(true);
        const reapply = await reapplyLead(lead.id);
        if (reapply.success) {
            const pingd = await pingAndWait(reapply.data.id);
            if (pingd.success) {
                setReapplying(false);
                history.push(`/apps/${pingd.lead.data.id}`);
            } else {
                notification.error({
                    message: 'Error',
                    description: 'There was an error reapplying',
                    duration: 5
                });
            }

        } else {
            notification.error({
                message: 'Error',
                description: 'There was an error reapplying',
                duration: 5
            });
        }
    }
    */

  async function doAcceptOffer() {
    if (data.originator.key === "fortiva" && !isSubmit) return
    if (_.has(copy, data.originator.key)) {
      history.push(
        `/offers/${offer_id}/apply`
        // state: { offer: data },
      )
    } else {
      const jwt = sessionStorage.getItem("jwt")
      const url = jwt ? `${data.apply_url}?token=${jwt}` : data.apply_url
      window.location.assign(url)
    }
  }

  if (error) {
    if (error?.response?.status === 404) {
      history.push("/404")
    }
  }

  if (loading || !data || !user || !merchant) {
    return <Spinner />
  }

  let component = null

  const acceptButton = (
    <Button type="primary" onClick={doAcceptOffer}>
      {data.originator.key === "genesis" || data.originator.key === "fortiva"
        ? "Accept Offer"
        : "Apply Now"}
    </Button>
  )

  switch (data.originator.key) {
    case "genesis":
      component = (
        <GenesisOffer
          acceptOffer={doAcceptOffer}
          offer={data}
          merchant={merchant}
          user={user}
        />
      )
      break
    case "finwise":
      component = (
        <AffFinwiseOffer offer={data} user={user} acceptButton={acceptButton} />
      )

      break
    case "americanfirst":
      component = (
        <AffFinwiseOffer offer={data} user={user} acceptButton={acceptButton} />
      )
      break
    case "fortiva":
      component = (
        <FortivaOffer
          offer={data}
          user={user}
          doAcceptOffer={doAcceptOffer}
          setIsSubmit={setIsSubmit}
        />
      )
      break
    case "citizens":
      component = (
        <CitizensOffer offer={data} user={user} doAcceptOffer={doAcceptOffer} />
      )
      break
    case "ownlease":
      component = (
        <OwnLeaseOffer offer={data} user={user} acceptOffer={doAcceptOffer} />
      )
      break
    case "uown":
      component = (
        <UownOffer offer={data} user={user} acceptOffer={doAcceptOffer} />
      )
      break
    case "flexshopper":
    case "flexshopper-external":
      component = (
        <FlexshopperOffer
          offer={data}
          user={user}
          acceptOffer={doAcceptOffer}
        />
      )
      break
    default:
      component = (
        <SingleOfferCard offer={data} user={user} acceptButton={acceptButton} />
      )
  }

  return (
    <>
      {/* {data &&
        data.status === "offered" &&
        data.originator.key === "citizens" &&
        merchant.code !== "trek" && (
          <LenderSummaryModal
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )} */}
      {data &&
        data.status === "offered" &&
        (data.originator.key === "finwise" ||
          data.originator.key === "americanfirst") && (
          <AffFinwiseSummaryModal
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )}
      {data &&
        // data.status === "offered" &&
        merchantId !== "trek" &&
        data.originator.key !== "finwise" &&
        data.originator.key !== "americanfirst" &&
        data.originator.key !== "ownlease" &&
        data.originator.key !== "flexshopper" &&
        data.originator.key !== "flexshopper-external" && (
          <OfferSummary
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )}

      {data.errors &&
        data.errors.map((e) => (
          <Alert
            message={e}
            key={e}
            type="error"
            showIcon
            closable
            style={{ marginBottom: 5 }}
          />
        ))}
      <Row
        justify="center"
        align="middle"
        style={{
          marginLeft: 0,
          textAlign: "center",
          marginTop: 10,
          marginRight: 0,
        }}
      >
        {component}
      </Row>
    </>
  )
}

export default OfferPage
