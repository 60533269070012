import { useState } from "react"
import { Form, Input, Select, AutoComplete } from "formik-antd"
import { HomeOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import { MAPBOX_API } from "../../utils/constants"
import state from "../../utils/state.json"

function AddressInput(props) {
  const { disabledFields } = props
  const [addresses, setAddresses] = useState([])
  async function queryMapbox(query) {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_API}&country=us`
    )
    const json = await response.json()

    const results = []
    const places = json.features

    if (places?.length > 0) {
      for (let i = 0; i < places.length; i++) {
        const place = places[i].place_name
        const index = place.lastIndexOf(",")
        const add = place.substring(0, index)
        results.push(renderItem(add, i))
      }
    }
    setAddresses(results)
  }

  function validateZip(value) {
    if (value && /^\d{5}(-\d{4})?$/.test(value) && value.length === 5)
      return undefined
    return props?.errorZipMessage || "Please enter a valid zip"
  }

  const renderItem = (title, count) => ({
    value: title,
    key: count,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <span style={{ paddingRight: 10 }}>
          <HomeOutlined />
        </span>
        {title}
      </div>
    ),
  })

  return (
    <>
      <Form.Item
        name="address.street1"
        hasFeedback={props.hasFeedback}
        showValidateSuccess={props.showValidateSuccess}
        required={props.required}
        label="Address"
        validate={(value) =>
          value.length === 0
            ? props?.errorAddressMessage || `Please enter a valid address`
            : undefined
        }
      >
        <AutoComplete
          style={{ textAlign: "left" }}
          name="address.street1"
          size="large"
          autoCapitalize="words"
          autoComplete="off"
          autoCorrect="off"
          disabled={disabledFields?.addressStreet1}
          placeholder={props.placeholder}
          options={addresses}
          onSelect={(option, value) => {
            const tokens = option.split(",")
            const stateZip = tokens[tokens.length - 1].split(" ")
            const zip = stateZip[stateZip.length - 1]
            const stateIndex = tokens[tokens.length - 1].indexOf(zip)
            props.formik.setFieldValue("address.street1", tokens[0])
            props.formik.setFieldValue("address.city", tokens[1].trim())
            props.formik.setFieldValue(
              "address.state",
              tokens[tokens.length - 1].substring(0, stateIndex).trim()
            )
            const stateInput = tokens[tokens.length - 1]
              .substring(0, stateIndex)
              .trim()
            state.forEach((s, i) => {
              if (s.State.toLowerCase() === stateInput.toLowerCase()) {
                props.formik.setFieldValue("address.state", s.Code)
              }
            })
            props.formik.setFieldValue("address.zip", zip)
          }}
          onChange={async (value) => {
            if (value.length > 3) await queryMapbox(value)
          }}
        />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="address.street2"
            hasFeedback
            label="Apartment or Unit #"
            tooltip="Please input Apt, Suite, etc. before entering the unit number"
            showValidateSuccess={false}
            required={false}
          >
            <Input
              name="address.street2"
              size="large"
              autoComplete="off"
              autoCorrect="off"
              placeholder="Apt or Unit #"
              disabled={disabledFields?.addressStreet2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="address.city"
            label="City"
            validate={(value) =>
              value.length === 0
                ? props?.errorCityMessage || `Please enter a valid city`
                : undefined
            }
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Input
              name="address.city"
              size="large"
              autoComplete="off"
              autoCorrect="off"
              placeholder="City"
              disabled={disabledFields?.addressCity}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="address.state"
            label="State"
            validate={(value) =>
              value.length === 0 ? `Please enter a valid state` : undefined
            }
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Select
              name="address.state"
              mode="single"
              autoComplete="off"
              autoCorrect="off"
              size="large"
              style={{ textAlign: "left" }}
              placeholder="State"
              disabled={disabledFields?.addressState}
            >
              {state.map((s) => (
                <Select.Option key={s.Code}>{s.State}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="address.zip"
            label="Zip"
            hasFeedback
            showValidateSuccess={false}
            required={false}
            validate={validateZip}
          >
            <Input
              name="address.zip"
              size="large"
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Zip"
              disabled={disabledFields?.addressZip}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default AddressInput
