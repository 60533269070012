import React from "react"
import { Form, Input, Select } from "formik-antd"
import { Row, Col } from "antd"
import PayrollFrequencySelect from "./PayrollFrequencySelect"

export default function BankAccountInput(props) {
  const accountTypes = [
    { value: "checking", option: "Checking " },
    { value: "savings", option: "Savings" },
  ]

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="bank_account.account_type"
            label="Account Type"
            validate={(value) => {
              if (typeof value === "undefined")
                return "Please select an account type"
              return undefined
            }}
            hasFeedback
            showValidateSuccess={false}
            required={false}
          >
            <Select
              name="bank_account.account_type"
              mode="single"
              autoComplete="off"
              autoCorrect="off"
              size="large"
              style={{ textAlign: "left" }}
              placeholder="Account Type"
            >
              {accountTypes.map((t) => (
                <Select.Option key={t.value}>{t.option}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PayrollFrequencySelect />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="bank_account.account_number"
            label="Account Number"
            required={false}
            hasFeedback
            validate={(value) => {
              if (typeof value === "undefined") {
                return "Please enter a valid account number"
              }
              if (value.length < 7) {
                return "Please enter a valid account number"
              }
              return undefined
            }}
          >
            <Input
              data-openreplay-obscured
              data-private="lipsum"
              size="large"
              name="bank_account.account_number"
              placeholder="Account Number"
              autoComplete="off"
              inputMode="numeric"
              autoCorrect="off"
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, "")
                props.formik.setFieldValue(
                  "bank_account.account_number",
                  value.slice(0, props.length)
                )
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="bank_account.routing_number"
            label="Routing Number"
            required={false}
            hasFeedback
            validate={(value) => {
              if (typeof value === "undefined") {
                return "Please enter a valid routing number"
              }
              const str = typeof value === "string" ? value : value.toString()
              if (str.length !== 9) {
                return "Please enter a valid routing number"
              }
              return undefined
            }}
          >
            <Input
              data-openreplay-obscured
              data-private="lipsum"
              size="large"
              name="bank_account.routing_number"
              placeholder="Routing Number"
              autoComplete="off"
              inputMode="numeric"
              autoCorrect="off"
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, "")
                props.formik.setFieldValue(
                  "bank_account.routing_number",
                  value.slice(0, props.length)
                )
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
