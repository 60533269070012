import React, { useEffect, useState, useCallback } from "react"
import {
  Space,
  Button,
  Statistic,
  Tag,
  Col,
  Row,
  Card,
  Collapse,
  Typography,
  Alert,
} from "antd"
import { RightOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

import CitizensOtp from "../../components/lenders/CitizensOtp"

import { formatStatus } from "../../utils/formatters"
// import { indexColors } from "../../utils/colors"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"

const { Panel } = Collapse
const { Title, Text } = Typography

function OffersView({ order, offer, setOffer, showApprove, setShowApprove }) {
  const { themeColor } = useTheme()
  const { merchantId } = useApply()

  const [panelKey, setPanelKey] = useState(null)
  const [validateOtp, setValidateOtp] = useState(false)
  const [isApprove, setIsApprove] = useState(false)

  const managePanel = (id) => {
    if (panelKey === id) {
      setPanelKey(null)
    } else {
      setPanelKey(id)
    }
  }

  const interestType = useCallback((offer) => {
    if (offer.intro_term) {
      if (offer.originator.key === "fortiva") return "Deferred"
      if (offer.apr === "0%") {
        if (offer.originator.key === "citizens") return "Same As Cash"
        return "Deferred"
      }
    }
    return "Standard"
  }, [])

  const estPayment = useCallback(
    (offer) => {
      if (offer.originator.key === "citizens") {
        if (merchantId === "trek" && offer.same_as_cash) {
          return `${offer?.intro_monthly_payment} for ${introMonths(
            offer?.intro_term
          )}/mo \n ${offer.monthly_payment} for ${goToMonths(offer)}/mo`
        }
        return offer.monthly_payment ? offer.monthly_payment : "-"
      }
      return offer.payment ? offer.payment : "-"
    },
    // eslint-disable-next-line
    []
  )

  const introMonths = useCallback((term) => {
    return term.split("Months")[0].trim()
  }, [])

  const goToMonths = useCallback((offer) => {
    const introMonths = Number(offer?.intro_term.split("Months")[0])
    const totalMonths = Number(offer?.term.split("Months")[0])
    return totalMonths - introMonths
  }, [])

  useEffect(() => {
    if (!showApprove) {
      setOffer({})
      setValidateOtp(false)
    }
    // eslint-disable-next-line
  }, [showApprove])

  useEffect(() => {
    if (offer?.actions?.apply || offer?.status === "otp_valid") {
      setValidateOtp(true)
      setShowApprove(true)
    }
    // eslint-disable-next-line
  }, [offer])

  useEffect(() => {
    if (order?.actions?.approve) {
      setIsApprove(true)
    } else {
      setIsApprove(false)
    }
  }, [order])

  return (
    <Card bodyStyle={{ margin: 0, padding: "1.2em" }}>
      {Object.keys(offer).length > 0 && !validateOtp ? (
        <CitizensOtp initialOffer={offer} updateOffer={setOffer} />
      ) : (
        <>
          {!isApprove && (
            <Alert
              message={<FormattedMessage id="OffersView.above.error" />}
              type="error"
              showIcon
              closable
              style={{ marginBottom: 5 }}
            />
          )}
          <Row
            justify="center"
            style={{ textAlign: "center", paddingTop: "2rem" }}
          >
            <Title style={{ fontWeight: 700 }} level={3}>
              {order.merchant?.name}
            </Title>
          </Row>
          {merchantId === "trek" && (
            <Row justify="center" style={{ textAlign: "center" }}>
              <Text style={{ fontWeight: 700 }} level={4}>
                Select an offer below to proceed with your purchase:
              </Text>
            </Row>
          )}

          {order.offers.map((offer, index) => (
            <Space
              key={offer.id}
              direction="vertical"
              size="middle"
              aria-disabled={!isApprove}
              style={{ display: "flex", opacity: isApprove ? 1 : 0.7 }}
            >
              <Card
                key={offer.id}
                onClick={() => setOffer(offer)}
                bodyStyle={{
                  paddingBottom: 0,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  paddingBottom: 0,
                  cursor: "pointer",
                }}
              >
                <Row justify="space-between">
                  <Col style={{ color: themeColor }}>
                    <strong style={{ fontWeight: 800, fontSize: 24 }}>
                      ${order.total}
                    </strong>
                  </Col>
                  <Col>
                    <Tag
                      style={{
                        color: "#ffffff",
                        borderRadius: 6,
                        borderColor: themeColor,
                        backgroundColor: themeColor,
                        fontSize: 14,
                        padding: 8,
                        fontWeight: 700,
                      }}
                    >
                      {offer.term ? offer.term : formatStatus(offer.loan_type)}
                    </Tag>
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }} justify="middle">
                  <Col span={5}>
                    <Statistic
                      title="Lender"
                      value={offer.originator.name}
                      valueStyle={{ fontSize: 14 }}
                    />
                  </Col>
                  <Col span={6}>
                    {offer?.same_as_cash ? (
                      <Statistic
                        title={"APR*"}
                        value={`${offer?.intro_apr} APR for ${introMonths(
                          offer?.intro_term
                        )} \n then ${offer.apr} APR \n for ${goToMonths(
                          offer
                        )} Months`}
                        valueStyle={{ fontSize: 14, whiteSpace: "pre-line" }}
                      />
                    ) : (
                      <Statistic
                        title="APR*"
                        value={offer.apr ? offer.apr : "-"}
                        valueStyle={{ fontSize: 14 }}
                      />
                    )}
                  </Col>
                  <Col span={5}>
                    <Statistic
                      title={"Interest Type"}
                      value={interestType(offer)}
                      valueStyle={{ fontSize: 14 }}
                    />
                  </Col>
                  <Col span={5}>
                    <Statistic
                      title="Est. Payment"
                      value={estPayment(offer)}
                      valueStyle={{ fontSize: 14, whiteSpace: "pre-line" }}
                    />
                  </Col>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <RightOutlined
                      onClick={() => managePanel(offer.id)}
                      style={{
                        fontWeight: 1800,
                        fontSize: 18,
                        color: themeColor,
                      }}
                      rotate={offer.id === panelKey ? 90 : 0}
                    />
                  </Col>
                </Row>
                <Collapse
                  style={{ margin: 0, padding: 0 }}
                  activeKey={panelKey}
                  ghost
                >
                  <Panel
                    key={offer.id}
                    showArrow={false}
                    header={null}
                    style={{
                      height: offer.id === panelKey ? "100%" : "100%",
                    }}
                  >
                    <img
                      width="120px"
                      src={offer.originator.image_url}
                      alt="lender-logo"
                    />
                    <Row>
                      <Button onClick={() => setOffer(offer)}>Apply</Button>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Space>
          ))}
        </>
      )}
    </Card>
  )
}

export default OffersView
