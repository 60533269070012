import React, { useEffect } from "react"
import { useParams, Redirect } from "react-router-dom"
import { Badge } from "antd"
import { useLead } from "../hooks/hooks"
import { Spinner } from "../components/core/Spinner"
import LeadView from "../components/lead/LeadView"
import ReApplyPage from "../components/lead/ReApplyPage"
import { formatLeadStatus, getStatusColor } from "../utils/formatters"
import { useApply } from "../hooks/useApply"
import { useTheme } from "../hooks/useTheme"

export default function LeadPage() {
  const { lead_id } = useParams()
  const { error, loading, data } = useLead(lead_id)
  const { fetchMerchant, setMerchant, setLead } = useApply()

  const { themeColor } = useTheme()

  console.debug("Lead:====> ", data)

  useEffect(() => {
    if (data) {
      fetchMerchant(data.merchant.code)
      setMerchant(data.merchant.code)
      setLead(data)
    }
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (data?.status) {
      const { id, status } = data
      window.parent &&
        window.parent.postMessage({ type: "lead", id, status }, "*")
    }
    //eslint-disable-next-line
  }, [data?.status])

  /**
   * Redirect to offer
   *
   * If a lead has an offer and only one offer
   * send the user to the offer
   */

  if (data && data.status === "pending") {
    return <ReApplyPage lead={data} />
  }

  if (
    data &&
    data.offers.length === 1 &&
    data.status === "approved" &&
    data.offers[0].status === "offered"
  ) {
    return <Redirect to={{ pathname: `/offers/${data.offers[0].id}` }} />
  }

  if (error) {
    console.debug(error?.response?.status, error?.message)
  }

  if (loading || !data) {
    return <Spinner />
  }

  console.log("data status", data.status)

  return (
    <Badge.Ribbon
      text={formatLeadStatus(data.status)}
      color={getStatusColor("lead", data.status, themeColor)}
    >
      <LeadView lead={data} />
    </Badge.Ribbon>
  )
}
