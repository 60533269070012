import axios from "axios"
import { API_URL } from "../utils/constants"

export const fetchMerchant = async (mid) => {
  try {
    const response = await axios.get(`${API_URL}/v1/merchants/search/${mid}`)
    return { success: true, data: response.data }
  } catch (err) {
    return { success: false, error: err.message }
  }
}

export const getCategories = async (url) => {
  try {
    const response = await axios.get(`${url}categories`)
    return { success: true, data: response.data }
  } catch (err) {
    return { success: false, error: err.message }
  }
}

export const getProducts = async (url) => {
  try {
    const response = await axios.get(`${url}`)
    return { success: true, data: response.data }
  } catch (err) {
    return { success: false, error: err.message }
  }
}
