import { useMemo, useState } from "react"
import { Row, Card, Button, Typography, Checkbox } from "antd"
import { FormattedMessage } from "react-intl"

import FormattedTitle from "../core/FormattedTitle"
import ThreatMetrix from "../compliance/ThreatMetrix"
import { useApply } from "../../hooks/useApply"

const { Text } = Typography

export default function CitizensOffer({ offer, doAcceptOffer }) {
  console.log("Offer is", offer)
  const { merchantId } = useApply()

  const [checked, setChecked] = useState(false)

  const reviewDisclosures = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "CitizensFooter.apply"
      default:
        return "general.reviewDisclosures"
    }
  }, [merchantId])

  return (
    <>
      <ThreatMetrix leadId={offer.lead.id} />
      <Card bodyStyle={{ maring: 0 }} style={{ width: "100%" }}>
        {merchantId === "trek" ? (
          <FormattedTitle id="CitizensOffer.headerTitle" size="medium" />
        ) : (
          <>
            <img src={offer.originator.image_url} alt="lender-logo" />
            <br />
            <br />
          </>
        )}

        <FormattedTitle id="CitizensOffer.title" size="medium" />
        <FormattedTitle id="CitizensOffer.subtitle" size="small" />
        <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>
          {offer.amount}
        </p>
        <br />
        {merchantId !== "trek" && (
          <div>
            <b>
              <FormattedMessage id="general.purchaseApr" />
              :&nbsp;&nbsp;
            </b>
            {offer.apr}
          </div>
        )}
        {merchantId !== "trek" && (
          <div>
            <b>
              <FormattedMessage id="general.interestType" />
              :&nbsp;&nbsp;
            </b>
            {offer.intro_term && offer.apr === "0%"
              ? "Same As Cash"
              : "Standard"}
          </div>
        )}
        {offer.payment ? (
          <div>
            <b>
              <FormattedMessage id="general.payment" />
              :&nbsp;&nbsp;
            </b>
            {offer.payment}
          </div>
        ) : null}
        {merchantId !== "trek" && (
          <>
            <p style={{ marginTop: 15, fontStyle: "italic", fontSize: 11 }}>
              <FormattedMessage id="CitizensOffer.terms" />
            </p>
            <p>
              <FormattedMessage id="CitizensOffer.review" />
            </p>
          </>
        )}

        {merchantId === "trek" && (
          <Row justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
            <Checkbox name="agree" onChange={() => setChecked(!checked)}>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  fontColor: "black",
                }}
              >
                I would like to apply for this line of credit according to the{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.citizensbank.com/disclosures/genericapr-ri-citizenspay.aspx"
                >
                  Citizens Pay Line of Credit Terms and Conditions
                </a>
                . I understand this application will result in a hard credit
                inquiry which may impact my credit score. I acknowledge that I
                have accessed, read, and agree to your{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.citizensbank.com/account-safeguards/privacy.aspx"
                >
                  Privacy Policy
                </a>
                ,{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.citizensbank.com/disclosures/apr-econsent.aspx"
                >
                  Consent to Electronic Documents
                </a>
                , and{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.citizensbank.com/disclosures/apr-verification-of-identity.aspx"
                >
                  Verification of My Identity
                </a>
                . I authorize Citizens Pay to obtain my credit report to
                determine financing options and share my information with
                retailers about my prequalification request, application, and
                line of credit.
              </Text>
            </Checkbox>
          </Row>
        )}
        <Row justify="center">
          <Button
            type="primary"
            onClick={doAcceptOffer}
            disabled={merchantId === "trek" && !checked}
          >
            <FormattedMessage id={reviewDisclosures} />
          </Button>
        </Row>
        {merchantId === "trek" && (
          <Row justify="center" style={{ marginTop: 20 }}>
            <Text
              style={{
                textAlign: "center",
                fontSize: 12,
                fontColor: "black",
              }}
            >
              *0% APR - 9.99% APR for 6-12 Months Based on Credit: Offer
              available on qualifying purchases of select products charged to a
              Citizens Pay Line of Credit account. Minimum purchase for first
              transaction: $199-499, based on creditworthiness. Equipment
              purchase must be included in first transaction. Minimum subsequent
              purchase: $99. Promotional offers are based on creditworthiness
              with a 0% APR -9.99% APR from the date an eligible purchase is
              posted to your account for 6-12 months, then 29.99% APR for
              remaining term. Monthly payment and total finance charges are
              estimates, do not include taxes, and may vary from the actual
              minimum payment due and interest charges that will be shown on
              your Citizens Pay Line of Credit statement. Limited time offer.
              Other transactions charged to your account affect overall minimum
              payment amount. Regular account terms apply to non-promo
              purchases. Standard Purchase APR is 29.99% for non-promotional
              purchases. Account must be in good standing. Subject to credit
              approval.{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.citizensbank.com/disclosures/genericapr-ri-citizenspay.aspx"
              >
                Click here
              </a>{" "}
              for important information about Rates and Fees and the Citizens
              Pay Line of Credit Agreement. Citizens Pay Line of Credit Account
              offered by Citizens Bank, N.A.
              <br />
              <br />
              Promotional APR: Offer available on qualifying purchases of select
              products charged to a Citizens Pay Line of Credit account. 0% APR
              - 9.99% APR from the date an eligible purchase is posted to your
              account until paid in full, based on term length. Minimum purchase
              for first transaction: $499. Equipment purchase must be included
              in first transaction. Minimum subsequent purchase: $99. Monthly
              payment and total finance charges are estimates, do not include
              taxes, and may vary from the actual minimum payment due and
              interest charges that will be shown on your Citizens Pay Line of
              Credit statement. Limited time offer. Standard Purchase APR is
              29.99% for non-promotional purchases. Subject to credit approval.
              Other transactions affect overall minimum payment amount. Regular
              account terms apply to non-promo purchases. Account must be in
              good standing.{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.citizensbank.com/disclosures/genericapr-ri-citizenspay.aspx"
              >
                Click here
              </a>{" "}
              for important information about Rates and Fees and the Citizens
              Pay Line of Credit Agreement. Citizens Pay Line of Credit Account
              offered by Citizens Bank, N.A.
            </Text>
          </Row>
        )}
      </Card>
    </>
  )
}
