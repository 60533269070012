import { useState, useEffect, useMemo } from "react"
import { Alert, Space, Row, Card, Button, Input, notification } from "antd"
import { LockOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import FormattedTitle from "../core/FormattedTitle"
import { useTheme } from "../../hooks/useTheme"
import { useApply } from "../../hooks/useApply"
import {
  sendCitizensOtp,
  validateCitizensOtp,
} from "../../services/offer.service"

export default function CitizensOtp({ initialOffer, updateOffer }) {
  const { colors, isMobile, themeColor } = useTheme()
  const { merchantId } = useApply()
  const [offer, setOffer] = useState(initialOffer)
  const [loading, setLoading] = useState(false)
  const [disableInput, setDisableInput] = useState(true)
  const [disableOtp, setDisableOtp] = useState(true)
  const [channel, setChannel] = useState(null)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [code, setCode] = useState(null)

  /**
   * Update the offer in parent component if can apply.
   *
   * TODO: Does this need to check for the status as well?
   */
  useEffect(() => {
    if (offer.actions.apply || offer.status === "otp_valid") {
      updateOffer(offer)
    }
  }, [offer, updateOffer])

  /**
   * Update the inputs based on status
   *
   */
  useEffect(() => {
    if (offer.actions.send_otp) {
      setDisableOtp(false)
    }
    if (offer.actions.validate_otp) {
      setDisableInput(false)
    }
    if (channel === null) {
      // setDisableOtp(true);
      setDisableInput(true)
    }
  }, [offer, channel])

  /**
   * Requests an OTP to the selected channel.
   *
   * @param {phone|mobile} channel to verify
   */
  async function handleSend(channel) {
    setChannel(channel)
    const response = await sendCitizensOtp(offer.id, channel)
    if (response.success) {
      setOffer(response.data)
      setShowSuccessAlert(true)
    } else {
      setShowErrorAlert(true)
    }
  }

  /**
   * Validates the OTP code to the selected channel.
   *
   * TODO: This doesn't handle if the page is refreshed between - need to use that as the basis of disabling the buttons.
   * If channel is null - then we disable?
   */
  async function handleValidate() {
    setLoading(true)
    const response = await validateCitizensOtp(offer.id, channel, code)
    setLoading(false)
    if (response.success) {
      setOffer(response.data)
    } else {
      const error =
        (response.error.data.code && response.error.data.code.join()) ||
        response.error.data.channel.join()
      notification.error({
        message: "Error",
        description: error,
      })
      console.log(response)
      // alert(JSON.stringify(response.error.data.code));
    }
  }

  const title = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "CitizensOtp.trek.title"
      default:
        return "CitizensOtp.title"
    }
  }, [merchantId])

  const submitBtn = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "CitizensOtp.cap.submit"
      default:
        return "CitizensOtp.submit"
    }
  }, [merchantId])

  const verifyPhone = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "CitizensOtp.trek.verifyPhone"
      default:
        return "CitizensOtp.verifyPhone"
    }
  }, [merchantId])

  const optSuccess = useMemo(() => {
    switch (merchantId) {
      case "trek":
        return "CitizensOtp.trek.success"
      default:
        return "CitizensOtp.success"
    }
  }, [merchantId])

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={<FormattedMessage id={optSuccess} />}
          type="success"
          showIcon
        />
      )}
      {showErrorAlert && (
        <Alert
          message={<FormattedMessage id="CitizensOtp.error" />}
          type="error"
          showIcon
        />
      )}
      <Card
        bodyStyle={{ margin: 0 }}
        style={{ paddingBottom: 10, marginTop: 5, width: "100%" }}
      >
        <Row justify="center">
          <LockOutlined
            style={{
              color: themeColor || colors.green,
              fontSize: isMobile ? 32 : 48,
            }}
          />
        </Row>
        <Row justify="center">
          <FormattedTitle id={title} size="medium" />
          <Row justify="center">
            <p style={{ width: "60%", textAlign: "center" }}>
              <FormattedMessage id="CitizensOtp.description" size="small" />
            </p>
          </Row>
          <Space>
            <Button disabled={disableOtp} onClick={() => handleSend("phone")}>
              <FormattedMessage id={verifyPhone} />
            </Button>
            {false && (
              <Button disabled={disableOtp} onClick={() => handleSend("mail")}>
                <FormattedMessage id="CitizensOtp.verifyEmail" />
              </Button>
            )}
          </Space>
        </Row>
        <Row justify="center" style={{ marginTop: 20, textAlign: "center" }}>
          <p style={{ width: "60%" }}>
            <FormattedMessage id="CitizensOtp.help" size="small" />
          </p>
        </Row>
        <Row justify="center" style={{ marginTop: 0 }}>
          <Input
            placeholder="One Time Passcode"
            style={{ width: 200, heigth: 60, textAlign: "center" }}
            onChange={(e) => setCode(e.target.value)}
            disabled={disableInput}
          />
        </Row>
        <Row justify="center" style={{ marginTop: 10 }}>
          <Button
            loading={loading}
            disabled={disableInput}
            onClick={handleValidate}
            style={{ width: 200 }}
            type="primary"
          >
            <FormattedMessage id={submitBtn} />
          </Button>
        </Row>
      </Card>
    </>
  )
}
