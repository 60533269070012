import React, { useEffect, useState } from "react"
import _ from "underscore"
import { useHistory } from "react-router-dom"
import { Card, notification, Row, Space } from "antd"
import { FormattedMessage } from "react-intl"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
import ApplyForm from "../forms/ApplyForm"
import Loader from "../core/Loader"
import {
  prepareLead,
  pingAndWait,
  updateLead,
} from "../../services/lead.service"
import BasicHeader from "../layout/BasicHeader"
import PoweredBy from "../core/PoweredBy"
import { ApplyCompliance } from "../compliance/ApplyCompliance"

function ReApplyPage({ lead }) {
  const { setLead } = useApply()
  const { merchant } = lead

  const { poweredByLogo } = useTheme()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledFields, setDisabledFields] = useState({
    personalFirstName: false,
    personalLastName: false,
    personalEmail: false,
    personalPhone: false,
    addressStreet1: false,
    addressStreet2: false,
    addressCity: false,
    addressState: false,
    addressZip: false,
  })

  async function onSubmit(values, actions) {
    actions.setSubmitting(true)
    setLoading(true)
    setIsSubmitted(true)

    let leadObj = await prepareLead(values)

    const seonSessionPayload = sessionStorage.getItem("seonSessionPayload")
    if (seonSessionPayload) {
      leadObj.device_profiles = leadObj.device_profiles || []
      leadObj.device_profiles.push({
        provider: "seon",
        session_id: seonSessionPayload,
      })
    }

    let resp = {}

    resp = await updateLead(lead.id, leadObj)

    if (resp.success) {
      const pingd = await pingAndWait(resp.data.id)
      if (pingd.success) {
        setLoading(false)
        actions.setSubmitting(false)
        console.debug("Ping success data:", pingd.data)
        setLead(pingd.lead.data)
        history.go(0)
      }
    } else {
      notification.error({
        message: "Error",
        description: (
          <FormattedMessage
            defaultMessage="There was an error processing your application"
            id="applyPage.error"
          />
        ),
        duration: 5,
      })
      _.keys(resp.error.data).forEach((k) => {
        if (_.isObject(resp.error.data[k])) {
          _.keys(resp.error.data[k]).forEach((kk) => {
            actions.setFieldError(`${k}.${kk}`, resp.error.data[k][kk][0])
          })
        } else {
          actions.setFieldError(k, resp.error.data[k][0])
        }
      })
      setIsSubmitted(false)
      setLoading(false)
    }
  }

  const fields = _.omit(merchant?.ping_fields, function (value) {
    if (_.isObject(value)) return _.keys(value).length === 0
    if (_.isBoolean(value)) return !value
    return false
  })

  function setDefaults(fields) {
    Object.entries(fields).forEach((entry) => {
      const [k, v] = entry
      if (_.isObject(v)) setDefaults(v)
      if (_.isBoolean(v)) fields[k] = ""
    })
  }

  function renderCompliance() {
    return (
      <Row style={{ textAlign: "center", paddingTop: 20 }}>
        <Space direction="vertical">
          <ApplyCompliance />
        </Space>
      </Row>
    )
  }

  setDefaults(fields)
  fields.merchant = { url: merchant.url }
  fields.amount = merchant.code === "citizens" ? 2000 : 1000
  fields.purpose = "large_purchases"
  fields.agree = false
  const additionalCompliance = null

  fields.personal = {
    dob: lead?.personal?.dob || "",
    email: lead?.personal?.email || "",
    first_name: lead?.personal?.first_name || "",
    last_name: lead?.personal?.last_name || "",
    phone: lead?.personal?.phone || "",
    ssn: lead?.personal?.ssn || "",
  }

  fields.income = {
    annual_income: lead?.income?.annual_income || "",
  }

  fields.employment = {
    status: lead?.employment?.status || "",
  }

  fields.address = {
    address_length: lead?.address?.address_length || "",
    city: lead?.address?.city || "",
    state: lead?.address?.state || "",
    status: lead?.address?.status || "",
    street1: lead?.address?.street1 || "",
    street2: lead?.address?.street2 || "",
    type: lead?.address?.type || "",
    zip: lead?.address?.zip || "",
  }

  useEffect(() => {
    if (lead) {
      setDisabledFields({
        ...disabledFields,
        personalFirstName: lead.personal?.first_name ? true : false,
        personalLastName: lead.personal?.last_name ? true : false,
        personalEmail: lead.personal?.email ? true : false,
        personalPhone: lead.personal?.phone ? true : false,
        addressStreet1: lead.address?.street1 ? true : false,
        addressStreet2: lead.address?.street2 ? true : false,
        addressCity: lead.address?.city ? true : false,
        addressState: lead.address?.state ? true : false,
        addressZip: lead.address?.zip ? true : false,
      })
    }
    /* eslint-disable */
  }, [lead])

  return (
    <Card>
      <BasicHeader
        text={
          <FormattedMessage
            id="ApplyPage.header"
            defaultMessage="Complete your application to get offers instantly!"
          />
        }
      />
      <Loader
        visible={loading}
        title={
          <FormattedMessage
            id="ApplyPage.loaderTitle"
            default="Searching For Offers"
          />
        }
        body={
          <FormattedMessage
            id={
              merchant.code === "fortiva"
                ? "ApplyPage.loaderBody.fortiva"
                : "ApplyPage.loaderBody"
            }
            defaultMessage="Please while while we search our lender network"
          />
        }
      />
      <ApplyForm
        values={fields}
        submitText={
          <FormattedMessage
            defaultMessage="Search for Offers"
            id={
              merchant.code === "trek"
                ? "buttons.apply.trek"
                : "ApplyForm.submit"
            }
          />
        }
        onSubmit={onSubmit}
        isSubmitted={isSubmitted}
        additionalCompliance={additionalCompliance}
        merchantCode={merchant.code}
        disabledFields={disabledFields}
      />
      {renderCompliance()}
      {poweredByLogo && <PoweredBy />}
    </Card>
  )
}

export default ReApplyPage
