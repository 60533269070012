import { Form, Input } from "formik-antd"
import * as React from "react"

function MoneyInput(props) {
  function validate(value) {
    if (typeof value === "undefined") return undefined
    if (typeof value === "object") return "Please enter a valid amount"
    let parsed
    if (typeof value === "number") {
      parsed = value
    } else {
      parsed = parseInt(value.replace(/\D/g, ""))
    }
    const minDisplay = `$${props.min}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const maxDisplay = `$${props.max}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (props.min !== undefined && props.max !== undefined) {
      console.log(props.min, props.max, parsed)
      console.log(parsed >= props.min, "is parsed gre eq?")
      return parsed && parsed >= props.min && parsed <= props.max
        ? undefined
        : `Please enter an amount between ${minDisplay} and ${maxDisplay}`
    }
    if (props.min === undefined && props.max !== undefined) {
      if (parsed < props.max) return undefined
      if (props.maxErrorMessage) return props.maxErrorMessage
      return `Please enter an amount less than ${maxDisplay}`
    }
    return parsed && parsed >= props.min
      ? undefined
      : `Please enter an amount greater than ${minDisplay}`
  }

  return (
    <Form.Item
      name={props.name}
      hasFeedback={props.hasFeedback}
      required={props.required}
      label={props.label}
      validate={validate}
      tooltip={props.tooltip}
    >
      <Input
        name={props.name}
        size="large"
        inputMode="numeric"
        style={props.style}
        placeholder={props.placeholder}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "")
          const parsed = value.replace(/\$\s?|(,*)/g, "")
          const display = `$ ${parsed}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          props.formik.setFieldValue(props.name, display)
        }}
      />
    </Form.Item>
  )
}

export default MoneyInput
